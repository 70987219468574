/* .intro {
  max-height: 100vh;
  overflow: hidden;
} */

.hero {
  position: relative;
  padding-bottom: 56.25%;

  /* width: 100%;
  height: 700px; */
  /* background-image: linear-gradient(
      0deg,
      rgba(36, 53, 110, 0.7),
      rgba(36, 53, 110, 0.7)
    ),
    url(../images/hero.jpg); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  /* margin: 0px auto;
  overflow: hidden; */
}

@media screen and (max-width: 420px) {
  .hero {
    padding-bottom: 30%;
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: auto; */
  /* max-height: 100vh; */
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background: linear-gradient(92.04deg, #233d97 3.45%, #192550 96.55%), #f0f0f0;
}

.titleDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 600px;
  padding-left: 250px;
  padding-top: 200px;
}

.title {
  margin-top: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  color: white;
}

.mainTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 1.22;
  color: white;
}

.button {
  width: 170px;
  height: 50px;
  margin-right: 20px;
  color: white;
  background-color: #e04e49;
  border: 0;
  outline: none;
  border-radius: 1px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.button:hover,
.button:focus {
  background-color: #fff;
  color: #e04e49;
  border: 1px solid #e04e49;
  box-sizing: border-box;
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }
}

.socials {
  position: absolute;
  bottom: 20%;
  right: 5%;
  display: flex;
  flex-direction: column;
}

a {
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 350%;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #e04e49;
  transform: scale(1.2);
}

.aboutUsBlock {
  height: 700px;
  background-color: #fff;
}

.aboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1100px; */
  max-width: 100vh;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .aboutUs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 180px;
    padding-bottom: 140px;
  }
}

.imageAboutUs {
  margin-right: auto;
  margin-left: auto;
}

.textAboutUsDiv {
  padding-left: 60px;
  width: 400px;
  margin-top: 90px;
}

@media screen and (max-width: 767px) {
  .textAboutUsDiv {
    width: 350px;
    padding-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .aboutUs {
    max-width: 100vw;
  }
}

@media screen and (min-width: 1280px) {
  .textAboutUsDiv {
    width: 600px;
    padding-left: 60px;
    margin-right: 60px;
  }
}

@media screen and (min-width: 1700px) {
  /* .aboutUs {
    display: flex;
    justify-content: start;
    
  } */

  .textAboutUsDiv {
    width: 600px;
    padding-left: 60px;
    margin-right: 360px;
  }
}

.moreAboutTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.22;
  color: #000;
  opacity: 0.5;
}

.titleAboutUs {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 1.22;
  margin-top: 20px;
  margin-bottom: 20px;
}

.textTitle {
  width: 350px;
  margin-bottom: 40px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 180%;

  color: #24356e;
  background-image: url(../images/quote.svg);
  background-repeat: no-repeat;
  background-size: 90px;
}

@media screen and (max-width: 768px) {
  .textTitle {
    width: 340px;
  }
}

.text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 180%;
  margin-bottom: 40px;
}

/* Our Companies */

.ourCompanies {
  background-color: #fff;
}

.ourCompaniesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100px;
  color: #fff;
  background: linear-gradient(92.04deg, #233d97 3.45%, #192550 96.55%), #f0f0f0;
}

@media screen and (max-width: 767px) {
  .ourCompanies {
    padding-top: 380px;
  }

  .ourCompaniesDiv {
    flex-direction: column;
    height: 370px;
  }
}

@media screen and (min-width: 768px) {
  .ourCompanies {
    padding-top: 380px;
  }

  .ourCompaniesDiv {
    flex-direction: column;
    height: 220px;
  }
}

@media screen and (min-width: 1440px) {
  .ourCompaniesDiv {
    height: 120px;
  }
}

.ourCompaniesTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.22;
  margin-right: 50px;
}

@media screen and (min-width: 1024px) {
  .ourCompanies {
    padding-top: 0;
  }

  .ourCompaniesDiv {
    flex-direction: row;
    height: 220px;
  }
}

@media screen and (min-width: 1440px) {
  .ourCompaniesDiv {
    height: 120px;
  }
}

@media screen and (max-width: 767px) {
  .ourCompaniesTitle {
    margin-right: 0;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .ourCompaniesTitle {
    margin-right: 0px;
    /* margin-left: 80px; */
  }
}

@media screen and (min-width: 1441px) {
  .ourCompaniesTitle {
    margin-right: 150px;
  }
}

.listOfCompanies {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-right: 70px;
}

.itemOfCompanies {
  position: relative;
}

@media screen and (max-width: 767px) {
  .listOfCompanies {
    /* justify-content: center;
    align-items: center; */
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
  }

  .itemOfCompanies {
    margin-bottom: 20px;
    /* margin-left: -30px; */
  }
}

@media screen and (min-width: 768px) {
  .ourCompanies {
    padding-top: 0;
  }

  .listOfCompanies {
    margin-right: 0;
    margin-top: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .listOfCompanies {
    margin-top: 20px;
  }
}

/* .linkToCompanies{
  display: none;
  width: 220px;
  height: 220px;
  background-color: #fff;
  box-shadow:0 5px 5px rgba(0,0,0,0.3);
} */

/* .linkToCompanies:hover,
.linkToCompanies:focus {
  display: block;
  position: absolute;
  top: 0;
  z-index:9999;

} */

.squareComp {
  display: none;

  width: 220px;
  height: 180px;
  margin-top: -80px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  transition: transform 250ms cubic-bezier(0.5, 0, 0.75, 0);
}

.itemOfCompanies:hover .squareComp,
.itemOfCompanies:focus .squareComp {
  display: block;
  position: absolute;
  top: 30%;
  z-index: 9999;
}

@media screen and (max-width: 769px) {
  .itemOfCompanies:hover .squareComp,
  .itemOfCompanies:focus .squareComp {
    display: none;
  }
}

.companiesSvg {
  width: 180px;
  height: 50px;
  margin-right: 70px;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 769px) {
  .companiesSvg {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1441px) {
  .companiesSvg {
    margin-right: 120px;
  }
}

.companiesDetails {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.83;
  margin-top: 10px;
  color: #e04e49;
}

.companiesDetails:hover,
.companiesDetails:focus {
  color: #000;
}

.companiesHover {
  width: 180px;
  height: 50px;
  margin-left: 20px;
  margin-top: 58px;
}

/* Our Services */

.ourServicesDiv {
  text-align: center;
  padding-top: 80px;
  background-color: #fff;
}

.ourServices {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .ourServices {
    width: 350px;
  }
}

@media screen and (min-width: 768px) {
  .ourServices {
    width: 400px;
    display: flex;
    justify-content: center;
  }
  /* .ourServices {
    width: 750px;
  } */
}

@media screen and (min-width: 1280px) {
  .ourServices {
    width: 1200px;
  }
}

.ourServicesTitle {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.37;
  color: #000000;
  opacity: 0.5;
}

.ourServicesMainTitle {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.22;
  text-align: center;
}

.listOfOurServices {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 769px) {
  .listOfOurServices {
    width: 750px;
  }
}

.itemOfOurServices {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 425px;
  margin-right: 30px;
  margin-bottom: 30px;
  background-color: #f2f3f4;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 767px) {
  .itemOfOurServices {
    width: 280px;
    margin-right: 0;
  }
}

@media screen and (min-width: 1441px) {
  .itemOfOurServices {
    margin-right: 60px;
    margin-bottom: 60px;
    height: 450px;
  }
}

.itemOfOurServices :nth-child(3n) {
  margin-right: 0;
}

.itemOfOurServices :nth-last-child(-n + 3) {
  margin-bottom: 0;
}

.itemOfOurServices:hover,
.itemOfOurServices:focus {
  transform: translateY(-20px);
  background: linear-gradient(180deg, #233d97 45.1%, #192550 100%);
  color: #fff;
  box-shadow: 0px 46px 85px rgba(36, 53, 110, 0.23),
    0px 21.2672px 39.2981px rgba(36, 53, 110, 0.170585),
    0px 12.1686px 22.4855px rgba(36, 53, 110, 0.14416),
    0px 7.38625px 13.6485px rgba(36, 53, 110, 0.124188),
    0px 4.45054px 8.22383px rgba(36, 53, 110, 0.105812),
    0px 2.47835px 4.57956px rgba(36, 53, 110, 0.0858404),
    0px 1.06592px 1.96964px rgba(36, 53, 110, 0.0594147);
}

.itemsPhoto {
  width: 300px;
  height: 200px;
}

@media screen and (max-width: 767px) {
  .itemsPhoto {
    width: 280px;
  }
}

@media screen and (min-width: 1441px) {
  .itemsPhoto {
    height: 230px;
  }
}

.titleOfOurServices {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 180%;
  text-align: center;
}

.textOfOurServices {
  width: 230px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
}

.linkToServices {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.83;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #e04e49;
}

.linkToServices:hover,
.linkToServices:focus {
  color: #fff;
}

.buttonServices {
  composes: button;
  /* margin-bottom: 80px; */
}

/* Swiper */

.swiper {
  /* position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%); */
  /* max-width: 100vw; */
  height: 600px;
}

@media screen and (max-width: 767px) {
  .swiper {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }
}

.slides {
  display: flex;
  align-items: center;
}

.slides:nth-child(1) {
  margin-left: 50px;
}

@media screen and (max-width: 767px) {
  .slides {
    margin-right: 30px;
  }

  .slides:nth-child(1) {
    margin-left: 35px;
  }
}

@media screen and (min-width: 2560px) {
  .slides:nth-child(1) {
    margin-left: 90px;
  }
}
/* Services Main Page from folder Our Services*/

.guarantee {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 80px; */
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  text-align: left;
  background-color: #fff;
}

/* @media screen and (max-width: 767px) {
  .guarantee {
    width: 350px;
    flex-direction: column;
    margin-top: 0;
  }
} */

@media screen and (max-width: 1279px) {
  .guarantee {
    flex-direction: column;
    margin-top: 0;
  }
}

.guaranteeFirstBlock {
  width: 450px;
  margin-right: 80px;
}

@media screen and (max-width: 767px) {
  .guaranteeFirstBlock {
    width: 350px;
    margin-right: 0;
  }
}

@media screen and (min-width: 1441px) {
  .guaranteeFirstBlock {
    margin-right: 220px;
  }
}

.guaranteeSecondBlock {
  width: 600px;
}

.quaranteeTitle {
  font-family: Gilroy;
  font-weight: bold;
  font-size: 45px;
  line-height: 1.8;
}

.quaranteeText {
  font-family: Gilroy;
  font-size: 14px;
  line-height: 1.78;
}

@media screen and (max-width: 767px) {
  .quaranteeTitle {
    font-size: 36px;
  }

  .guaranteeSecondBlock {
    width: 350px;
  }
}

/* benefits */

.benefitsDiv {
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 100vw;
  text-align: center;
  background-color: #fff;
}

.benefits {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
  padding-top: 60px;
  /* margin-right: auto;
  margin-left: auto; */
  background: linear-gradient(92.04deg, #233d97 3.45%, #192550 96.55%), #f0f0f0;
}

.titleBenefits {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.37;
  color: #ffffff;
  opacity: 0.5;
  margin-bottom: 50px;
}

.mainTitleBenefits {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.22;
  color: white;
  margin-bottom: 100px;
}

.allBenefits {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .benefits {
    padding-top: 30px;
  }

  .titleBenefits {
    margin-bottom: 20px;
  }

  .mainTitleBenefits {
    margin-bottom: 40px;
    font-size: 28px;
  }

  .allBenefits {
    margin-left: -30px;
  }
}

@media screen and (min-width: 768px) {
  .allBenefits {
    margin-left: 20px;
  }
}

.benefitsSvg {
  display: flex;
  align-items: flex-end;
  width: 200px;
  margin-right: 50px;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 767px) {
  .benefitsSvg {
    width: 60px;
  }
}

@media screen and (min-width: 768px) {
  .benefitsSvg {
    width: 120px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .benefitsSvg {
    width: 160px;
    margin-right: 100px;
  }
}

.benefitsSvg:hover,
.benefitsSvg:focus {
  transform: translateY(-12px) scale(1.1);
}

.benefitsSvg:nth-child(5n) {
  margin-right: 0;
}

.components {
  position: relative;
}

@media screen and (max-width: 767px) {
  .components {
    width: 50px;
    margin-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .components {
    width: 140px;
    margin-left: 0px;
  }
}

@media screen and (min-width: 1024px) {
  .components {
    width: 170px;
  }
}

@media screen and (min-width: 1280px) {
  .components {
    margin-left: 70px;
  }
}

.subtext {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-right: 50px;
  margin-bottom: 100px;
  opacity: 0.7;
}

.subtext:nth-child(5n) {
  margin-right: 0;
}

.number {
  position: absolute;
  top: -33%;
  left: 18%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  opacity: 0.1;
}

@media screen and (max-width: 767px) {
  .subtext {
    font-size: 10px;
    margin-bottom: 70px;
  }

  .number {
    font-size: 52px;
    top: -25%;
    left: 0%;
  }
}

@media screen and (min-width: 768px) {
  .number {
    top: -40%;
    left: 5%;
  }
}
/* Compleated Projects */

.compleatedProjectsDiv {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;
}

.compleatedProjects {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .compleatedProjects {
    width: 350px;
  }
}

@media screen and (min-width: 768px) {
  .compleatedProjects {
    width: 768px;
  }
}

@media screen and (min-width: 1280px) {
  .compleatedProjects {
    width: 1280px;
  }
}

.titleProjects {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.37;
  color: #000000;
  opacity: 0.5;
}

.mainTitleProjects {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.22;
  margin-bottom: 40px;
}

.listOfProjects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 15px;
  margin-bottom: 60px;
}

.itemProject {
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 767px) {
  .mainTitleProjects {
    font-size: 28px;
  }

  .itemProject {
    width: 310px;
    height: 350px;
    margin-right: 0;
  }
}

@media screen and (min-width: 1280px) {
  .itemProject {
    width: 450px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1440px) {
  .itemProject {
    margin-right: 60px;
    margin-bottom: 60px;
  }
}

.itemProject:nth-child(2n) {
  margin-right: 0;
}

.itemProject:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .itemProject {
    width: 450px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .itemProject:nth-last-child(-n + 2) {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .itemProject:nth-last-child(-n + 2) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1280px) {
  .itemProject {
    width: 450px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .itemProject:nth-last-child(-n + 2) {
    margin-bottom: 15px;
  }
}

.projectPhoto {
  width: 500px;
  height: 400px;
}

@media screen and (max-width: 767px) {
  .projectPhoto {
    width: 310px;
    height: 350px;
  }
}

@media screen and (min-width: 768px) {
  .projectPhoto {
    width: 450px;
  }
}

.textProjects {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 99%;
  color: #ffffff;

  background: linear-gradient(
    0deg,
    rgba(36, 53, 110, 0.75),
    rgba(36, 53, 110, 0.75)
  );
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 46px 85px rgba(36, 53, 110, 0.23),
    0px 21.2672px 39.2981px rgba(36, 53, 110, 0.170585),
    0px 12.1686px 22.4855px rgba(36, 53, 110, 0.14416),
    0px 7.38625px 13.6485px rgba(36, 53, 110, 0.124188),
    0px 4.45054px 8.22383px rgba(36, 53, 110, 0.105812),
    0px 2.47835px 4.57956px rgba(36, 53, 110, 0.0858404),
    0px 1.06592px 1.96964px rgba(36, 53, 110, 0.0594147);
  border-radius: 2px;
}

.projectTitle {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  line-height: 180%;
}

.projectText {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 14px;
  line-height: 180%;
}

.buttonToProject {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  right: 0;
  width: 120px;
  height: 50px;
  color: white;
  background-color: #e04e49;
  border: 0;
  border-radius: 1px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.buttonToProject:hover,
.buttonToProject:focus {
  background-color: #fff;
  color: #e04e49;
  border: 1px solid #e04e49;
}

.arrowRight {
  width: 15px;
  margin-left: 10px;
}

/* Ask Us */
.askUsDiv {
  display: flex;
  width: 100%;
  /* height: 400px; */
  padding-top: 80px;
  /* padding-bottom: 80px; */
  background-color: white;
}

.askUs {
  position: relative;
  display: flex;
  width: 100%;
  height: 300px;
  background: linear-gradient(92.04deg, #233d97 3.45%, #192550 96.55%), #f0f0f0;
}

@media screen and (max-width: 767px) {
  .askUs {
    height: 600px;
    flex-direction: column-reverse;
  }
}

.divForImg {
  display: flex;
  align-items: flex-end;
  /* height: 434px; */
  margin-left: 80px;
  margin-right: 100px;
}

@media screen and (max-width: 767px) {
  .divForImg {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1441px) {
  .divForImg {
    margin-left: 280px;
    margin-right: 300px;
  }
}

.askUsSvg {
  width: 400px;
  height: 300px;
}

@media screen and (max-width: 767px) {
  .askUsSvg {
    width: 320px;
    height: 250px;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 80px;
}

.titleAsks {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.37;
  color: #ffffff;
  opacity: 0.5;
}

@media screen and (max-width: 767px) {
  .textBlock {
    width: 320px;
    text-align: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .textBlock {
    width: 320px;
    text-align: center;
    align-items: center;
    margin-left: -140px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .textBlock {
    width: 520px;
    text-align: center;
    align-items: center;
    margin-left: 0;
    margin-bottom: 40px;
  }
}

.mainTitleAsks {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.22;
  color: white;
  margin-top: 0;
  margin-bottom: 40px;
}

.letter {
  position: absolute;
  top: -90%;
  right: 0;
  margin-top: 0;
  font-size: 600px;
  color: white;
  opacity: 0.05;
}

@media screen and (max-width: 1280px) {
  .letter {
    display: none;
  }
}



/* Modal */

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  background-color: rgba(36, 53, 110, 0.8);
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  max-width: 600px;
  /* width: 100%; */
  padding: 12px;
  margin-top: 50px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.iconBtn {
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  color: #fff;
  font: inherit;
  background-color: green;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Ask us modal */

.modal {
  position: relative;
}

.modalInfo {
  display: block;
  text-align: center;
  padding: 40px;
}

.feedback {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 1.22;

  color: #000000;
  opacity: 0.5;
}

.advice {
  font-family: Gilroy;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;

  color: #000000;
}

.sendMessage {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 180%;
  color: #e50914;
}

.contactUsMail {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
  background: 
    linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 0, 1)),
    linear-gradient(to right, rgba(255, 0, 0, 1), rgba(0, 100, 200, 1));
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms ease-in-out;
}

.contactUsMail:hover,
.contactUsMail:focus {
  background-size: 0 0.1em, 100% 0.1em;
}

.inputArea {
  display: flex;
  flex-direction: column;
}

.formModal {
  display: flex;
  justify-content: center;
}

.textfield {
  width: 400px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .textfield {
    width: 320px;
    margin-right: auto;
    margin-left: auto;
  }
}

.checkboxDiv {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1023px) {
  .checkboxDiv {
    flex-direction: column;
  }
}

.checkbox:checked + .agreement {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 180%;
  color: #24356e;
}

.cookies {
  color: #e04e49;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 18px;
}

.cookies:hover,
.cookies:focus {
  color: black;
}

.sendBtn {
  margin-left: 90px;
  border: none;
  outline: none;
  background-color: #fff;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.22;
  color: #e04e49;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .sendBtn {
    margin-left: 0;
  }
}

.sendBtn:hover,
.sendBtn:focus {
  color: #000;
}

.close {
  position: absolute;
  top: 2%;
  right: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  background-image: url('../images/close.svg');
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 200ms ease-in-out;
  transition: background-color var(--animation-duration) var(--timing-function);
  outline: none;
  cursor: pointer;
}

.close:hover,
.close:focus {
  transform: scale(1.2);
  border: none;
}

/* Map */

.mapDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 180px;
  /* padding-bottom: 180px; */
  background-color: white;
}

@media screen and (max-width: 767px) {
  .mapDiv {
    display: flex;
    flex-direction: column;
    /* width: 350px; */
    max-width: 100vw;
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 768px) {
  .mapDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .mapDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* height: 300px; */
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .mapDiv {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 2400px) {
  .mapDiv {
    padding-top: 80px;
    padding-bottom: 380px;
  }
}

.map {
  /* position: absolute;
  top: 20%;
  right: 0; */
  width: 800px;
  height: 500px;
  margin-right: 100px;
}

@media screen and (max-width: 767px) {
  .map {
    /* position: absolute;
    top: 55%;
    right: 0; */
    /* width: 320px; */
    max-width: 100vw;
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  .map {
    /* position: absolute;
    top: 35%;
    right: 5%; */
    width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1024px) {
  .map {
    /* position: absolute;
    top: 35%;
    right: 5%; */
    width: 900px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1280px) {
  .map {
    /* position: absolute;
    top: 25%;
    right: 5%; */
    /* width: 700px; */
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .mapLayer {
    width: 350px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  .mapLayer {
    width: 500px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1280px) {
  .mapLayer {
    width: 600px !important;
  }
}

@media screen and (min-width: 1700px) {
  .mapLayer {
    width: 900px !important;
  }
}

/* @media screen and (min-width: 2400px) {
  .mapLayer {
    width: 1500px !important;
  }
} */

.mapText {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 120px;
}

@media screen and (max-width: 767px) {
  .mapText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .mapText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .mapText {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 140px;
  }
}

@media screen and (min-width: 1440px) {
  .mapText {
    flex-direction: column;
    width: 400px;
    margin-left: 300px;
  }
}

@media screen and (min-width: 2400px) {
  .mapText {
    flex-direction: column;
    width: 400px;
    margin-left: 440px;
    /* margin-top: 200px; */
  }
}

.ourWorkText {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.22;
  color: #000000;

  opacity: 0.5;
}

.mapMainText {
  font-family: Gilroy;
  font-weight: bold;
  font-size: 45px;
  line-height: 1.2;

  color: #000000;
}

@media screen and (max-width: 1280px) {
  .mapMainText {
    font-size: 30px;
    width: 260px;
  }
}

.ourWorkDescription {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 14px;
  line-height: 180%;

  color: #000000;
}

@media screen and (max-width: 1280px) {
  .ourWorkDescription {
    width: 330px;
  }
}

.amountOurWork {
  margin-right: 30px;
}

/* Footer */

/* @media screen and (max-width: 769px) {
  .footer {
width: 768px;
  }
} */

.footerDiv {
  max-width: 100vw;
  height: 200px;
  padding-top: 40px;
  /* padding-bottom: 40px; */
  padding-right: 0px;
  padding-left: 0px;
  background: linear-gradient(0deg, #e5e7ee, #e5e7ee),
    linear-gradient(0deg, #292929, #292929), #24356e;
}

@media screen and (max-width: 767px) {
  .footerDiv {
    height: 740px;
    /* max-height: 100vh; */
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (min-width: 768px) {
  .footerDiv {
    /* max-width: 100%; */
    height: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .footerDiv {
    max-width: 100%;
    height: 300px;
  }
}

@media screen and (min-width: 1440px) {
  .footerDiv {
    width: 100%;
  }
}

.footerNavigation {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(36, 53, 110, 0.23);
}

.svgAllComp {
  display: flex;
  width: 220px;
  margin-left: 40px;
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  .footerNavigation {
    flex-wrap: wrap;
  }

  .svgAllComp {
    justify-content: center;
    flex-wrap: wrap;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
  }
}

.footerCompSvg {
  width: 60px;
  height: 20px;
  margin-right: 30px;
  /* cursor: pointer; */
}

@media screen and (min-width: 768px) {
  .footerNavigation {
    align-items: center;
  }

  .footerCompSvg {
    width: 60px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1280px) {
  .footerNavigation {
    padding-left: 60px;
  }

  .footerCompSvg {
    width: 80px;
    margin-right: 60px;
  }
}

@media screen and (min-width: 2400px) {
  .svgAllComp {
    margin-left: 220px;
  }

  .footerCompSvg {
    width: 80px;
    margin-right: 100px;
  }
}

.footerCompSvg:nth-child(3n) {
  margin-right: 0;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  .navigation {
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 250px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* @media screen and (min-width: 769px) {
  .nav {
    display: flex;
    align-items: center;
    width: 460px;
  } */

.navlink {
  margin-right: 20px;
  text-decoration: none;
  color: #24356e;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (max-width: 767px) {
  .navlink {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .navigation {
    justify-content: center;
    /* flex-wrap: wrap; */
    margin-right: 0;
  }

  .navlink {
    font-size: 12px;
    margin-right: 10px;
  }

  .nav {
    width: 320px;
    margin-right: 0;
    margin-top: -20px;
  }
}

@media screen and (min-width: 1280px) {
  .navlink {
    font-size: 14px;
    margin-right: 45px;
  }

  .nav {
    width: 580px;
  }
}

@media screen and (min-width: 2400px) {
  .nav {
    width: 1200px;
    margin-left: 100px;
  }

  .navlink {
    font-size: 18px;
    margin-right: 60px;
  }
}

.activeNavlink {
  composes: navlink;
  color: #e50914;
}

/* .socialsFooter {
  width: 200px;
} */

.socialsText {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.22px;
  color: #ababab;
}

@media screen and (max-width: 767px) {
  .socialsText {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .socialsText {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .socialsText {
    margin-left: 40px;
    margin-right: 80px;
    align-items: center;
  }
}

.linkedin {
  cursor: pointer;
  width: 30px;
  /* margin-left: 20px; */
}

@media screen and (max-width: 767px) {
  .linkedin {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 10px;
  }
}

/* @media screen and (min-width: 1024px) {
  .linkedin {
    margin-left: -60px;
  }
} */

.arrowGoUp {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.25;
  color: #24356e;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .arrowGoUp {
    margin-bottom: 45px;
    margin-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .arrowGoUp {
    margin-bottom: 30px;
    margin-left: 30px;
  }
}

@media screen and (min-width: 1280px) {
  .arrowGoUp {
    margin-right: 30px;
  }
}

.arrow {
  margin-bottom: -7px;
  transform: rotate(180deg);
}

.adressesDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 0;
  margin-left: 0;
}

.adress {
  display: flex;
  align-items: center;
}

.listOfAdresses {
  display: flex;
  height: 120px;

  list-style: none;
}

.itemOfAdresses {
  width: 250px;
  height: 50px;
  margin-right: 90px;
}

@media screen and (max-width: 767px) {
  .adressesDiv {
    /* width: 350px; */
    /* max-width: 100vw; */
    flex-wrap: wrap;
  }

  .listOfAdresses {
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    margin-top: 90px;
  }

  .itemOfAdresses {
    width: 230px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
  }

  .itemOfAdresses:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .adressesDiv {
    flex-wrap: wrap;
  }

  .adress {
    max-width: 100%;
  }

  .listOfAdresses {
    justify-content: center;
    width: 730px;
  }

  .itemOfAdresses {
    width: 230px;
    margin-right: 15px;
  }

  .itemOfAdresses:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .adressesDiv {
    flex-wrap: nowrap;
  }

  .listOfAdresses {
    width: 800px;
  }

  .itemOfAdresses {
    width: 250px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .listOfAdresses {
    width: 800px;
  }

  .itemOfAdresses {
    width: 280px;
    margin-right: 50px;
  }
}

@media screen and (min-width: 2400px) {
  .listOfAdresses {
    width: 1400px;
  }

  .itemOfAdresses {
    width: 350px;
    margin-right: 100px;
  }
}

.telephone {
  width: 200px;
  height: 10px;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 14px;
  line-height: 200%;

  color: #959595;
}

.tel {
  color: #959595;
}

.mailSvg {
  margin-right: 5px;
}

.mail {
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
}

.policy {
  margin-right: 40px;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.32;

  color: #24356e;
}

@media screen and (max-width: 767px) {
  .policy {
    /* display: flex;
    justify-content: center;
    flex-wrap: wrap; */
    margin-top: 40px;
    margin-right: 20px;
    margin-top: 100px;
  }
}

@media screen and (min-width: 768px) {
  .policy {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .policy {
    width: 200px;
    flex-wrap: wrap;
    margin-left: -40px;
  }
}

.policyAndCookies {
  cursor: pointer;
}

.copyright {
  display: flex;
  justify-content: flex-end;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.32;

  color: #a5a5a5;
}
