.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 40px;
  margin-left: 40px;
}

.firstNav {
  display: flex;
}

@media screen and (max-width: 768px) {
  .firstNav {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .burgerMenu {
    display: none;
  }
}

.af {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.navlink {
  margin-right: 25px;
  text-decoration: none;
  color: #425583;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
}

.navlink:hover,
.navlink:focus {
  transform: none;
}

.activeNavlink {
  composes: navlink;
  color: #e50914;
}

.navButton {
  margin-right: 15px;
  text-decoration: none;
  color: #425583;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  border: none;
}

.navButton:hover,
.navButton:focus {
  transform: scale(1.1);
  cursor: pointer;
}

.button {
  width: 8px;
  margin-right: 35px;
  margin-left: -30px;
  border: none;
  background-color: inherit;
}

.button:hover,
.button:focus {
  background-color: inherit;
  border: none;
  outline: none;
  transform: scale(1.1);
}

.arrow {
  margin-bottom: -7px;
  transform: rotate(180deg);
}

.menu {
  margin-top: 20px;
}

/* @media screen and (min-width: 769px) {
  .menu {
    display: none;
  }
} */

.menuItem {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  align-items: center;
  height: 40px;

  font-family: Montserrat;
  font-size: 16px;
  color: #24356e;
}

.link:hover,
.link:focus {
  transform: none;
}

.arrowRight {
  width: 15px;
  margin-left: 10px;
}
