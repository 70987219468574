.wrapper {
  height: 100%;
}

.container {
  box-sizing: border-box;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background-color: #e5e5e5;
  color: #000000;
}
